body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.menu-default .ant-drawer-body {
  padding: 0;
  display: flex;
}

.menu-default .ant-menu-item:hover, .ant-menu-item-active,
.menu-default .ant-menu-item-selected {
  color: #f73838;
}

.menu-default .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f7383814;
}

.menu-default .ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #f73838;
}

.ant-spin-dot-item,
.default-container  .ant-spin-dot-item{
  background-color: #f73838 !important;
}